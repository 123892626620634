import React, { useEffect } from 'react';

import logo from '../../../images/on-the-road/d1/logo.svg';
import IconThree from '../../../images/on-the-road/d5/gestao.png';
import Map from '../../../images/on-the-road/d5/map.svg';
import IconOne from '../../../images/on-the-road/d5/tecnologia.png';
import IconTwo from '../../../images/on-the-road/d5/tendencia.png';
import useResizer from '../../../utils/useResizer';
import { Btn } from './btn';
// import d1logo from "../../../images/on-the-road/d1/logo.svg"
import { Airplane } from './icon';
import { Container, PillarsWrapper } from './styles';

const Pillars = () => {
  const isMobile = useResizer();

  return (
    <PillarsWrapper>
      <Container className="container" id="pilares">
        <h5>
          3 pilares do <i>On The Road</i>
        </h5>
        <div className="pillars">
          <div>
            <div className="icon-wrapper">
              <img src={IconOne} alt="Tecnologia" />
            </div>
            <h6>Tecnologia</h6>
            <p>
              A tecnologia mudou o mundo e, cada vez mais, o setor imobiliário vem aproveitando os recursos digitais
              para acelerar a jornada do inquilino e dos corretores de locação.
            </p>
          </div>
          <div>
            <div className="icon-wrapper">
              <img src={IconTwo} alt="Tendência" />
            </div>
            <h6>Tendência</h6>
            <p>
              Com o novo morar, o mercado imobiliário precisou se adaptar e se aproximar de um novo público, que evoluiu
              com a internet e espera dela um canal para resolver problemas e conquistar sonhos.
            </p>
          </div>
          <div>
            <div className="icon-wrapper">
              <img src={IconThree} alt="Gestão" />
            </div>
            <h6>Gestão</h6>
            <p>
              Estar atento às parcerias que fortalecem o mercado com capacitação da equipe contribui para a alta
              performance com um atendimento humanizado. Cada vez mais, atualização é a chave para a eficiência da
              gestão.
            </p>
          </div>
        </div>
      </Container>
      <div className="pillars-container">
        <div id="registrar-interesse">
          <div className="pillars-event">
            <Airplane color="#003863" width="63" height="45" display="none" />
            <div className="row">
              {/* <div className="pillars-next-event"> */}
              {/* <div className="pillars-next-event-header">
                <div>
                  {!sentForm && (
                    <>
                      <h3>ENCONTRE O PRÓXIMO ON THE ROAD CREDPAGO</h3>
                      <b>Registre seu interesse.</b>
                    </>
                  )}
                </div>
                <img src={d1logo} />
              </div>
              <div>
                {!sentForm ? (
                  <form onSubmit={handleSubmit}>
                    <div>
                      <input
                        name="name"
                        id="name"
                        value={name}
                        onChange={event => setName(event.target.value)}
                        placeholder="Nome completo"
                        required
                      />
                    </div>
                    <div>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        value={email}
                        placeholder="E-mail"
                        onChange={event => setEmail(event.target.value)}
                        required
                      />
                    </div>
                    <div className="footer">
                      <div>
                        <label htmlFor="politica_privacidade">
                          <input
                            type="checkbox"
                            name="politica_privacidade"
                            id="politica_privacidade"
                            value={politicaPrivacidade}
                            onChange={event =>
                              setPoliticaPrivacidade(event.target.value)
                            }
                            required
                          />
                          Ao enviar suas informações em nossos formulários, você
                          declara estar de acordo com a nossa{" "}
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://credpago.com.br/politica-de-privacidade"
                          >
                            Política de Privacidade
                          </a>
                          . A CredPago recomenda que sejam enviadas apenas
                          informações solicitadas, evitando compartilhamento
                          desnecessário de dados, principalmente sensíveis.
                        </label>
                      </div>
                      <div>
                        <Btn type="submit">Enviar</Btn>
                      </div>
                    </div>
                  </form>
                ) : (
                  <div>
                    <div className="sent">
                      <div>
                        <h3>Obrigado por seu interesse!</h3>
                        O próximo On The Road já está no radar. <br /> Em breve,
                        enviaremos mais informações.
                      </div>
                    </div>
                    <div className="sent-footer">
                      <Btn
                        onClick={() => {
                          setSentForm(false)
                        }}
                      >
                        Voltar
                      </Btn>
                    </div>
                  </div>
                )}
              </div> */}
              <div id="ingressos">
                <div>
                  Adquira seu ingresso
                  <br />
                  agora mesmo!
                </div>
                <div>
                  Sua jornada do conhecimento
                  <br />
                  está prestes a {!isMobile && <br />}
                  decolar!
                </div>
              </div>
              <a
                href="https://scheneventos.com.br/Credpago"
                className="col individual tooltip"
                target="_blank"
                data-tooltip="Esse link redireciona para um endereço externo, portanto fora da CredPago, com suas próprias políticas e controles de segurança."
                rel="noreferrer"
              >
                <h1>INDIVIDUAL</h1>
                <h1>
                  <img src={logo} alt="ingresso" />
                </h1>
                <h1>BRASÍLIA-DF</h1>
                <h1>
                  <div></div> R$ 390,00
                </h1>
                <h1>R$ 345,00</h1>
                <Btn>Garanta seu ingresso</Btn>
              </a>
            </div>
          </div>
          <div className="pillars-map">
            <img src={Map} alt="Map" />
          </div>
        </div>
      </div>
    </PillarsWrapper>
  );
};

export default Pillars;
